import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Driverinfo.css'
import { useLocation } from 'react-router-dom';
import OwnerNavbar from './OwnerNavbar';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Footer from "./Footer";
import Backdrop from '@mui/material/Backdrop';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
const DriverInfo = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [openBackdrop, setOpenBackdrop] = React.useState(false);

  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [loadingStatus, setLoadingStatus] = useState('');
  // const [crn, setCRN] = useState('');
  const [driverName, setDriverName] = useState('');
  const [tbrInputs, setTbrInputs] = useState({});
  const [driverDetails, setDriverDetails] = useState([]);
  const [tbrloadingstatus,settbrLoadingStatus]=useState([])
  const [filteredDriverDetails, setFilteredDriverDetails] = useState([]);
  const [status, setTbrStatus] = useState(''); // Track TBR status
  const [tbr, setTBR] = useState(''); // Track entered TBR
  const [error,setError]=useState('')
  // useEffect(() => {
  //   if (location.state && location.state.crn) {
  //     setCRN(location.state.crn);
  //     console.log('CRN:', location.state.crn);
  //   }
  // }, [location.state]);
  const crn = localStorage.getItem('userCRN');
  const phonenumber = localStorage.getItem('phoneNumber');

  useEffect(() => {
    fetchDriverData(crn);
  }, [crn]);
  const token = localStorage.getItem('userToken')

const headers = {
    Authorization: `Bearer ${token}`,
  };
  useEffect(()=>{
    if(!token){
  navigate('/Login')
    }
  })
  const fetchDriverData = (crn) => {
    axios
      .get(`https://mynode1.trucksbooking.in/fetchdriver?crn=${crn}`,{headers:headers})
      .then((response) => {
        setDriverDetails(response.data);
        setFilteredDriverDetails(response.data);
        setDriverName(response.data.driverName);
        setPhoneNumber(response.data.phoneNumber);
        setTbrStatus(response.data.status);
        console.log(response)
      })
      .catch((error) => {
        console.error('Error fetching driver details:', error);
      });
  };
  useEffect(() => {
    const uniquePhoneNumbers = [...new Set(driverDetails.map((driver) => driver.phoneNumber))];
    setPhoneNumbers(uniquePhoneNumbers);
    const tbrInputInitialState = {};
    driverDetails.forEach((driver) => {
      tbrInputInitialState[driver.id] = '';
    });
    setTbrInputs(tbrInputInitialState);
  }, [driverDetails]);

  const handleFetchDetails = () => {
    console.log('Selected Phone Number:', phoneNumber);
  
    if (phoneNumber) {
      // Filter based on the selected phone number
      const filteredDrivers = driverDetails.filter(
        (driver) => String(driver.phoneNumber) === String(phoneNumber)
      );
      console.log('Filtered Drivers:', filteredDrivers);
      console.log('Driver Details After Filtering:', filteredDrivers);
      console.log('driverdetials', driverDetails);
      setFilteredDriverDetails(filteredDrivers);
    } else {
      // If no phone number is selected, show all drivers
      console.log('No Phone Number Selected');
      console.log('Driver Details Without Filtering:', driverDetails);
      setFilteredDriverDetails(driverDetails);
    }
  };
  
  useEffect(() => {
    if (tbr && crn) {
      verifyTBR(tbr, crn);
    }
  }, [tbr, crn]);

  const verifyTBR = (tbr, crn) => {
    axios
      .get(`https://mynode1.trucksbooking.in/verifytbr?tbr=${tbr}&crn=${crn}`, { headers: headers })
      .then((response) => {
        setLoadingStatus(response.data.status);
        if (response.data.status !== 'pending') {
          setError('Invalid TBR or not in pending status');
        } else {
          setError('');
        }
      })
      .catch((error) => {
        console.error('Error verifying TBR:', error);
        setError('Error verifying TBR');
      });
  };
console.log(tbrloadingstatus)
const handleSendMessage = async (driverId) => {
  try {
    const selectedDriver = driverDetails.find((driver) => driver.id === driverId);
    const enteredTBR = tbrInputs[driverId];

    if (!enteredTBR) {

      setError({ ...error, [driverId]: 'Enter the TBR' });
      return;
    }
 setOpenBackdrop(true);
    // Fetch TBR details to validate
    const response = await axios.get(`https://mynode1.trucksbooking.in/verifytbr?crn=${crn}&tbr=${enteredTBR}`, { headers: headers });
    const tbrDetailsArray = response.data;
    const tbrDetails = tbrDetailsArray[0];

    console.log('TBR Details:', tbrDetails);
    console.log('Loading Status:', tbrDetails.loadingStatus);
    console.log('CRN:', tbrDetails.crn);

    if (!tbrDetails || tbrDetails.loadingStatus !== 'pending') {
      setError({ ...error, [driverId]: 'Invalid TBR or TBR is not in pending status' });
      setOpenBackdrop(false);
      return;
    }
    
    setOpenBackdrop(true);
      // Send a PUT request to update driver's status by TBR number
      await axios.put(`https://mynode1.trucksbooking.in/updatebookingstatus`, {
        tbr: enteredTBR,
        driverphonenumber: selectedDriver.phoneNumber,
        driverName: selectedDriver.driverName,
        driverstatus: 'sent',
      },{headers:headers});
      
      // Send a PUT request to update booking's status by driver ID
      await axios.put(`https://mynode1.trucksbooking.in/updatedriverstatus/${driverId}`, { status: 'sent' },{headers:headers});
  
      // Add a new record
      await axios.post('https://mynode1.trucksbooking.in/addnewrecord', {
        tbr: enteredTBR,
        name: selectedDriver.driverName,
        phonenumber: selectedDriver.phoneNumber,
        crn,
        localDate: new Date().toLocaleString(), // Assuming localDate is the current date and time
      },{headers:headers});
  
      // Update the TBR status in the state
  
      // Disable the input field and the Send button
      setOpenBackdrop(false);
      window.location.reload()
    } catch (error) {
      console.error('Error sending message:', error);
      setOpenBackdrop(false);
    }
  };
  const markDriverAvailable = async(driverId) => {
    setOpenBackdrop(true);
    try {
      // Send a PUT request to update driver's status to 'pending' by driver I
      await axios.put(`https://mynode1.trucksbooking.in/markdriveravailable/${driverId}`, { status: 'pending'},{  headers: headers 
      });
  
      // Log a message indicating that the driver is now available
      console.log(`Driver with ID ${driverId} is now marked as pending.`);
      setOpenBackdrop(false);
      window.location.reload()
      // You can also update the state or perform additional actions as needed
    } catch (error) {
      console.error('Error marking driver as pending:', error);
      setOpenBackdrop(false);
    }
    console.log(`Driver with ID ${driverId} is now available`);
  };
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 7; // Number of items per page
  
  
  // Slice filteredDriverDetails to show only the items for the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentDrivers = filteredDriverDetails.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredDriverDetails.length / itemsPerPage);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  return (
    <div className='driver-info-bg'>
      <OwnerNavbar/>
      
      <div className="container" id="driverinfo">
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
        onClick={null} // Set onClick to null to disable closing on click
      >
        <div>
          <CircularProgress color="inherit" />
          <br />
          <Typography variant="h6" color="inherit" component="div" style={{ marginTop: '10px' }}>
            Loading...
          </Typography>
        </div>
      </Backdrop>

      <div style={{ backgroundColor: '#e2eff1', paddingTop: '1px' }} className="shadow">
        <h2 className="mt-5 txt" style={{ fontFamily: 'Segoe UI', textShadow: '1px 2px 2px gray', textAlign: 'center' }}>
          Driver Info
        </h2>

        <div className="mb-4 ms-3" style={{ display: 'flex' }}>
          <div>
            <select
              className="form-control mt-5"
              style={{ maxWidth: '240px' }}
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            >
              <option value="">Select Phone Number</option>
              {phoneNumbers.map((phone) => (
                <option key={phone} value={phone}>
                  {phone}
                </option>
              ))}
            </select>
          </div>
          <div>
            <Button className="text-light ms-3 mt-5" variant="contained" color="success" onClick={handleFetchDetails}>
              Get Info
            </Button>
          </div>
        </div>

        <div style={{ overflow: 'hidden', overflowX: 'scroll', maxHeight: '400px' }}>
          <table className="table table-sm table-bordered" style={{ textAlign: 'center' }}>
            <thead className="thead">
              <tr>
                <th style={{ textAlign: 'center', fontWeight: 'normal' }} id="thead">S.No</th>
                <th style={{ textAlign: 'center', fontWeight: 'normal' }} id="thead">Driver Name</th>
                <th style={{ textAlign: 'center', fontWeight: 'normal' }} id="thead">Phone Number</th>
                <th style={{ textAlign: 'center', fontWeight: 'normal' }} id="thead">Date of Joining</th>
                <th style={{ textAlign: 'center', fontWeight: 'normal' }} id="thead">TBR</th>
                <th style={{ textAlign: 'center', fontWeight: 'normal' }} id="thead">Send Driver</th>
                <th style={{ textAlign: 'center', fontWeight: 'normal' }} id="thead">Mark Available</th>
              </tr>
            </thead>
            <tbody>
              {currentDrivers.length === 0 ? (
                <tr>
                  <td colSpan="7" className="text-danger" style={{ fontWeight: 'bold' }}>No drivers found.</td>
                </tr>
              ) : (
                currentDrivers.map((driver, index) => (
                  <tr key={driver.id}>
                    <td>{indexOfFirstItem + index + 1}</td>
                    <td>{driver.driverName}</td>
                    <td>{driver.phoneNumber}</td>
                    <td>{driver.dateOfJoining}</td>
                    <td>
                      {driver.status === 'sent' ? (
                        <span>Driver Sent</span>
                      ) : (
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter TBR"
                          value={tbrInputs[driver.id] || ""}
                          onChange={(e) => setTbrInputs({ ...tbrInputs, [driver.id]: e.target.value })}
                          disabled={driver.status === 'sent'}
                          maxLength={20}
                          required
                        />
                      )}
                      <span style={{ color: 'red' }}>{error[driver.id]}</span>
                    </td>
                    <td>
                      <Button
                        className="btn btn-primary"
                        onClick={() => handleSendMessage(driver.id)}
                        disabled={driver.status === 'sent'}
                      >
                        Send
                      </Button>
                    </td>
                    <td>
                      <Button
                        className={`btn ${driver.status === 'sent' ? 'btn-danger' : 'btn-success'}`}
                        onClick={() => markDriverAvailable(driver.id)}
                        disabled={driver.status !== 'sent'}
                      >
                        {driver.status === 'sent' ? 'Not Available' : 'Available'}
                      </Button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>

        {/* Pagination Controls */}
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
          <Button onClick={handlePreviousPage} disabled={currentPage === 1}>
            Previous
          </Button>
          <span style={{ margin: '0 10px', fontSize: '1rem' }}>
            Page {currentPage} of {totalPages}
          </span>
          <Button onClick={handleNextPage} disabled={currentPage === totalPages}>
            Next
          </Button>
        </div>
      </div>
    </div>
    </div>
  );
};

export default DriverInfo;
