import React, { useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table, Button, InputGroup, FormControl } from 'react-bootstrap';

const Pincode = () => {
  const [pincode, setPincode] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const [editable, setEditable] = useState({});

  const handleSearch = () => {
    // Make a GET request to your backend API with the entered pincode
    axios.get(`http://localhost:9001/Pincode/${pincode}`)
      .then((response) => {
        setSearchResult(response.data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setSearchResult([]);
      });
  };

  const handleEdit = (rowData) => {
    setEditable({ ...editable, [rowData.id]: true });
  };

  const handleSave = (rowData) => {
    // Make a PUT request to update the edited data in the backend
    axios.put(`http://localhost:9001/Pincode/${rowData.id}`, rowData)
      .then(() => {
        setEditable({ ...editable, [rowData.id]: false });
        // Optionally, you can fetch the updated data from the server and set it in the state
        // Or, you can update the specific row in the state with the changes made
        // Update the state as per your application structure
      })
      .catch((error) => {
        console.error('Error updating data:', error);
      });
  };

  return (
    <div style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column', background: '#f4f4f4', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
      <InputGroup className="mb-3 mt-4" style={{ maxWidth: '80%', margin: '0 auto' }}>
        <FormControl
          placeholder="Enter Pincode"
          style={{ maxWidth: '70%', marginRight: '10px' }}
          value={pincode}
          onChange={(e) => setPincode(e.target.value.replace(/\D/g, ''))}
        />
        <Button variant="primary" onClick={handleSearch}>
          Search
        </Button>
      </InputGroup>

      <div style={{ flex: 1, overflowY: 'auto', padding: '20px', margin: '10px' }}>
        <Table striped bordered hover style={{ textAlign: 'center' }}>
          <thead>
            <tr>
              <th>Record NO</th>
              <th>Country</th>
              <th>State</th>
              <th>District</th>
              <th>Mandal</th>
              <th>Villages</th>
              <th>Pincode</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {searchResult.map(rowData => (
              <tr key={rowData.id}>
                <td>{rowData.id}</td>
                <td>
                  {editable[rowData.id] ? (
                    <FormControl
                      value={rowData.COUNTRY}
                      onChange={(e) => setSearchResult(prevData => prevData.map(item => (item.id === rowData.id ? { ...item, COUNTRY: e.target.value } : item)))}
                    />
                  ) : (
                    rowData.COUNTRY
                  )}
                </td>
                <td>
                  {editable[rowData.id] ? (
                    <FormControl
                      value={rowData.STATE}
                      onChange={(e) => setSearchResult(prevData => prevData.map(item => (item.id === rowData.id ? { ...item, STATE: e.target.value } : item)))}
                    />
                  ) : (
                    rowData.STATE
                  )}
                </td>
                <td>
                  {editable[rowData.id] ? (
                    <FormControl
                      value={rowData.DISTRICT}
                      onChange={(e) => setSearchResult(prevData => prevData.map(item => (item.id === rowData.id ? { ...item, DISTRICT: e.target.value } : item)))}
                    />
                  ) : (
                    rowData.DISTRICT
                  )}
                </td>
                <td>
                  {editable[rowData.id] ? (
                    <FormControl
                      value={rowData.MANDAL}
                      onChange={(e) => setSearchResult(prevData => prevData.map(item => (item.id === rowData.id ? { ...item, MANDAL: e.target.value } : item)))}
                    />
                  ) : (
                    rowData.MANDAL
                  )}
                </td>
                <td>
                  {editable[rowData.id] ? (
                    <FormControl
                      value={rowData.VILLAGES}
                      onChange={(e) => setSearchResult(prevData => prevData.map(item => (item.id === rowData.id ? { ...item, VILLAGES: e.target.value } : item)))}
                    />
                  ) : (
                    rowData.VILLAGES
                  )}
                </td>
                <td>
                  {editable[rowData.id] ? (
                    <FormControl
                      value={rowData.PINCODES}
                      onChange={(e) => setSearchResult(prevData => prevData.map(item => (item.id === rowData.id ? { ...item, PINCODES: e.target.value } : item)))}
                    />
                  ) : (
                    rowData.PINCODES
                  )}
                </td>
                <td>
                  {editable[rowData.id] ? (
                    <Button variant="primary" onClick={() => handleSave(rowData)}>Save</Button>
                  ) : (
                    <Button variant="secondary" onClick={() => handleEdit(rowData)}>Edit</Button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      {/* Your footer component goes here */}
    </div>
  );
};

export default Pincode;
