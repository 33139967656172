import * as React from 'react';
import { useState } from 'react';
import './PaymentInterface.css'
 


export default function PaymentInterface() {
    
    const [activeButton, setActiveButton] = useState(1);
  
    const showContent = (buttonNumber) => {
      setActiveButton(buttonNumber);
    };
  
  return (

    <div className='paymentbg'>
     <div className='div1'>
     <div className="container div2">
      <div className="row grid-row">
        <div className="col-md-3 buttons">
          <button className={`btn btn-warning ${activeButton === 1 ? 'active' : ''}`} onClick={() => showContent(1)}>QR CODE</button>
          <button className={`btn btn-warning ${activeButton === 2 ? 'active' : ''}`} onClick={() => showContent(2)}>Credit / Debit / ATM CARD</button>
          <button className={`btn btn-warning ${activeButton === 3 ? 'active' : ''}`} onClick={() => showContent(3)}> Net Banking </button>
          <button className={`btn btn-warning ${activeButton === 4 ? 'active' : ''}`} onClick={() => showContent(4)}>UPI</button>
          {/* <button className={`btn btn-primary ${activeButton === 5 ? 'active' : ''}`} onClick={() => showContent(5)}>Button 5</button> */}
        </div>
        <div className="col-md-9 mt-4">
          <div style={{ display: activeButton === 1 ? 'block' : 'none' }}>
          <div>
                          <div className="text-center">
                            <h6 style={{ color: 'red', animation: 'handshake 1.5s infinite' }}>SCAN WITH ANY PAYMENT MODE</h6>
                            <img src='' style={{ width: '50%' }} alt="QR Code" />
                          </div>

                          <p style={{ color: 'black' }}>Total Price:</p>
                          <h6 style={{ color: 'red' }}>Time remaining:</h6>
                          <div style={{ marginTop: '20px' }}>
                            <p>Security Information:</p>
                            <ul>
                              <li>Ensure a secure and stable internet connection.</li>
                              <li>Do not share the QR code with anyone else.</li>
                              <li>Make sure the payment is completed within the specified time.</li>
                            </ul>
                          </div>
                        </div>
          </div>
          <div style={{ display: activeButton === 2 ? 'block' : 'none' }}>Content for Button 2</div>
          <div style={{ display: activeButton === 3 ? 'block' : 'none' }}>Content for Button 3</div>
          <div style={{ display: activeButton === 4 ? 'block' : 'none' }}>
          <div>
    <h3>UPI PAYMENT</h3>
    <div style={{ marginTop: '20px' }}>
      <p>Total Price: <b></b></p>
      <p style={{ color: 'red', marginBottom: '0' }}>Time remaining: </p>      <div>
        <p>Security Information:</p>
        <ul>
          <li>Ensure a secure and stable internet connection.</li>
          <li>Make sure the payment is completed within the specified time.</li>
        </ul>
      </div>

      {/* Payment options with logos */}
      <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between' }}>
        {/* Add UPI payment option */}
        <div>
          <img src="upi-logo.png" alt="UPI" style={{ width: '50px', height: '50px' }} />
          {/* Add UPI payment logic */}
         
        </div>
        {/* Add Paytm payment option */}
        <div>
          <img src="paytm-logo.png" alt="Paytm" style={{ width: '50px', height: '50px' }} />
          {/* Add Paytm payment logic */}
          
        </div>
        {/* Add PhonePe payment option */}
        <div>
          <img src="https://www.google.com/imgres?imgurl=https%3A%2F%2Fimages.yourstory.com%2Fcs%2Fimages%2Fcompanies%2FlogosC2051575978102071png&tbnid=bdJHrCLCVd1cYM&vet=12ahUKEwiYiJOC0MKCAxUVpukKHdmKDasQMygAegQIARBw..i&imgrefurl=https%3A%2F%2Fyourstory.com%2Fcompanies%2Fphonepe%3Forigin%3Dawards&docid=l4Bmpdtmv1eUFM&w=418&h=417&q=phone%20pay&ved=2ahUKEwiYiJOC0MKCAxUVpukKHdmKDasQMygAegQIARBw" alt="PhonePe" style={{ width: '50px', height: '50px' }} />
          {/* Add PhonePe payment logic */}
          
        </div>
        {/* Add more payment options as needed */}
      </div>
    </div>
  </div>
          </div>
          {/* <div style={{ display: activeButton === 5 ? 'block' : 'none' }}>Content for Button 5</div> */}
        </div>
      </div>
    </div>
    </div>

    </div>
  )
}
