import React from 'react';
import { useNavigate } from 'react-router-dom';
import './SucessPayment.css';

export default function BookingConfirmed() {
    const navigate = useNavigate();

    // No useEffect with setTimeout, just navigate after rendering

    // Navigate to the "AgentBooking" page after rendering
    navigate('/AgentBooking');

    return (
        <div className="success-payment-container">
            <div className="success-content">
                {/* <img src='https://media.tenor.com/bm8Q6yAlsPsAAAAj/verified.gif' alt="Paytm Logo" className="paytm-logo" /> */}
                {/* <img src='https://media1.tenor.com/m/yEoIoC7Lb7wAAAAd/tick-verify.gif' alt="Paytm Logo" className="paytm-logo" /> */}

                {/* <img src='https://media.tenor.com/rr-muzxX2V4AAAAj/twitter-verified-check-mark.gif' alt="Paytm Logo" className="paytm-logo" /> */}
                <img src='https://media.tenor.com/hQ88OSFbTN8AAAAM/loading.gif' alt="Paytm Logo" className="paytm-logo" />

                <h2 className="success-heading" style={{ color: "#5BB318" }}>booking confirmed</h2>
            </div>
        </div>
    );
}
