import React, { useState,useEffect } from 'react';
import axios from 'axios';
import './AgentBooknow.css'; // Create a CSS file for styling
import AgentNavbar from './AgentNavbar';
import Modal from 'react-modal'; 
import { useLocation , useNavigate } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import Postpaidbookinganimation from "./Prepaidbookinganimation"
import { Form } from 'react-bootstrap';

export default function AgentBookNow() {
  const [selectedLoadWeight, setSelectedLoadWeight] = useState('');
  const [showAnimation,setShowAnimation] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [results, setResults] = useState([]);
  const [finalPrice, setFinalPrice] = useState(0);
    const [formData, setFormData] = useState({
        registrationNumber: '',
        wheels: '',
        maxWeight: '',
        fromLocation: '',
        fromSublocation: '',
        fromAddress: '',
        fromPincode: '',
        toLocation: '',
        toSublocation: '',
        toAddress: '',
        toPincode: '',
        type: '',
    });

    
    const [loadingSublocations, setLoadingSublocations] = useState([]);
    const [unloadingSublocations, setUnloadingSublocations] = useState([]);
    const [selectedLoadingSublocation, setSelectedLoadingSublocation] = useState('');
    const [selectedUnloadingSublocation, setSelectedUnloadingSublocation] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false); 
    const location = useLocation();
    const navigate = useNavigate();
    const main = localStorage.getItem('hello')
    useEffect(()=>{
      if(!main){
        navigate('/AgentInterface');
        return;
      }

    })
    const agentType = sessionStorage.getItem('agentType')
    const token = localStorage.getItem('agenttoken')
    const crn = sessionStorage.getItem('agentCRN')
const agentId = sessionStorage.getItem('agentId')
const phonenumber = sessionStorage.getItem('phoneNumber1')
console.log(agentType)
const name = sessionStorage.getItem('agentName')


useEffect(()=>{
  if(!token){
navigate('/Dashboard')
  }
})



    const { truckData } = location.state || {};
    console.log(truckData);
    console.log(crn)
    console.log(phonenumber)
    const {
      date,
      from,
      time,
      to,
      truckMaxWeight,
      truckNumber,
      truckWheels,
      
      ton,
      kilometer,
      pricePerTonKm,
      truckPostingID
     
      } = truckData;
     const totalkilometers= 100;
console.log("from pincode",formData.fromPincode)
      useEffect(() => {
        calculateAveragePrice();
      }, [selectedLoadWeight, pricePerTonKm, results]);
      console.log('calculateAveragePrice',selectedLoadWeight, pricePerTonKm, results)
    
      const calculateAveragePrice = () => {
        let averagePrice = 0;
        switch (selectedLoadWeight) {
          case '1-5T':
            averagePrice = (1 + 5) / 2 * pricePerTonKm * totalkilometers;
            break;
          case '6-10T':
            averagePrice = (6 + 10) / 2 * pricePerTonKm * totalkilometers;
            break;
            case '11-15T':
              averagePrice = (11 + 15) / 2 * pricePerTonKm * totalkilometers;
              break; 
               case '16-20T':
              averagePrice = (16 + 20) / 2 * pricePerTonKm * totalkilometers;
              break; 
               case '21-25T':
              averagePrice = (21 + 25) / 2 * pricePerTonKm * totalkilometers;
              break;  
              case '26-30T':
              averagePrice = (26 + 30) / 2 * pricePerTonKm * totalkilometers;
              break; 
              
          // Add more cases for other weight ranges
          default:
            // Handle default case if needed
            break;
        }
    
        // Update finalPrice with the calculated average price
        setFinalPrice(averagePrice);
        console.log('averagePrice',averagePrice)
      };
      const openModal = () => {
        console.log(selectedLoadingSublocation,selectedUnloadingSublocation,formData.fromAddress,formData.toAddress,formData.toPincode ,formData.fromPincode,formData.type ,selectedLoadWeight)
        if (
          !selectedLoadingSublocation ||
          !selectedUnloadingSublocation ||
          !formData.fromAddress ||
          !formData.toAddress ||
          !formData.fromPincode ||
          !formData.toPincode ||
          !formData.type ||
          !selectedLoadWeight
          
        ) {
          // Show an alert if any required field is empty
          alert('Please fill in all the required fields');
          return;
        }
        setIsModalOpen(true);
      };
    
      const closeModal = () => {
        setIsModalOpen(false);
      };
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const handleBookNowClick = async () => {
        const bookingStatus = await checkBookingStatus();

        if (bookingStatus === 'booked') {
            // Show message and prevent further action
            alert('You have already booked. Please go back and book again if needed.');
        } else {
        if (agentType === 'postpaid') {
          console.log('hih')
          await sendDataToBackend();
          deleteBookedTruck(truckNumber);
        } else if (agentType === 'prepaid') {
          console.log('prepaid agent');
          const bookingData = {
            truckNumber,
            truckWheels,
            truckMaxWeight,
            fromSublocation: formData.fromSublocation, // Updated
            toSublocation: formData.toSublocation,     // Updated
            date,
            from,
            time,
            to,
            fromPincode: formData.fromPincode,
            toPincode: formData.toPincode,
            totalkilometers,
            totalPrice: finalPrice,
            name,
            phonenumber,
            agentId,
            crn,
            type: formData.type,
            loadweight: selectedLoadWeight,
            fromAddress: formData.fromAddress,
            toAddress: formData.toAddress,
            truckPostingID
          };
          localStorage.setItem('bookingData', JSON.stringify(bookingData));
          const book = 'hello'
localStorage.setItem('book',book)
          // Navigate to the payment interface
          navigate('/PaymentInterface');
        } else {
        }}
      };
      const deleteBookedTruck = async (truckNumber) => {
        try {
          await axios.delete(`https://mynode1.trucksbooking.in/deltruck/${truckNumber}`,{headers:headers});
          navigate('/AgentBooking',);
        } catch (error) {
          console.error('An error occurred while deleting the truck:', error);
        }
      };
      const checkBookingStatus = async () => {
        console.log('track');
        try {
            const bookingData = {
                truckNumber,
                date,
                time,
            };
    
            // Make a request to backend to check booking status
            const response = await fetch('https://mynode1.trucksbooking.in/check-booking-status', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    // Include authorization token if needed
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(bookingData),
            });
    
            if (response.ok) {
              const data = await response.json();
              console.log('data', data);
              console.log('status', data[0].bookingstatustrack);
              return data[0].bookingstatustrack;
            } else {
                throw new Error('Failed to check booking status');
            }
        } catch (error) {
            console.error('An error occurred while checking booking status:', error);
            // Handle error
            return 'error'; // Or handle error in a different way
        }
    };
      const sendDataToBackend = async () => {
        const bookingData = {
          truckNumber,
          truckWheels,
          truckMaxWeight,
          fromSublocation: selectedLoadingSublocation, // Use selectedLoadingSublocation
          toSublocation: selectedUnloadingSublocation, // Use selectedUnloadingSublocation
          date,
          from,
          time,
          to,
          fromPincode: formData.fromPincode,
          toPincode: formData.toPincode,
          totalkilometers,
          totalPrice: finalPrice,
          name,
          phonenumber,
          agentId,
          crn,
          type: formData.type,
          loadweight:selectedLoadWeight,
          fromAddress: formData.fromAddress,
          toAddress: formData.toAddress,
          bookeddate: new Date(),
          paymentStatus:'PaymentPending',
          truckPostingID
        };
       
        try {
          const response = await axios.post('https://mynode1.trucksbooking.in/book', bookingData,{headers:headers});
    
          if (response.status === 200) {
            setShowAnimation(true);
            setTimeout(() => {
                navigate('/AgentBooking');
                localStorage.removeItem('hello');
              
            }, 6000);
            console.log(crn, phonenumber)
          } else {
            console.error('Error confirming booking');
          }
        } catch (error) {
          console.error('An error occurred while confirming booking:', error);
        }
      };
    
      const handleFormChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
      };
    
    

      
      function formatTruckNumber(truckNumber) {
        if (truckNumber && truckNumber.length >= 6) {
          const hiddenPart = '*'.repeat(6);
          const visiblePart = truckNumber.slice(6);
          return hiddenPart + visiblePart;
        }
        return truckNumber;
      }
      
      useEffect(() => {
        if (truckData.loadingSublocations) {
          const loadingSubs = truckData.loadingSublocations
            .slice(1, -1) // Remove leading and trailing curly braces
            .split(',')
            .map(sublocation => sublocation.trim()); // Remove leading and trailing whitespace
          setLoadingSublocations(loadingSubs);
        }
        if (truckData.unloadingSublocations) {
          const unloadingSubs = truckData.unloadingSublocations
            .slice(1, -1) // Remove leading and trailing curly braces
            .split(',')
            .map(sublocation => sublocation.trim()); // Remove leading and trailing whitespace
          setUnloadingSublocations(unloadingSubs);
        }
      }, [truckData]);
//       useEffect(() => {
//         if (formData.fromPincode && formData.toPincode) {
//             setShowMap(true);
//         } else {
//             setShowMap(false);
//         }
//     }, [formData]);
//     useEffect(() => {
//       if (showMap) {
//         const script1 = document.createElement('script');
//         script1.src = 'https://apis.mappls.com/advancedmaps/api/505cf304-dc21-4bc2-afdd-f84403cbb33b/map_sdk?layer=vector&v=3.0&callback=initMap1';
//         script1.async = true;
//         document.body.appendChild(script1);

//         const script2 = document.createElement('script');
//         script2.src = 'https://apis.mappls.com/advancedmaps/api/505cf304-dc21-4bc2-afdd-f84403cbb33b/map_sdk_plugins?v=3.0';
//         script2.async = true;
//         document.body.appendChild(script2);

//         return () => {
//             document.body.removeChild(script1);
//             document.body.removeChild(script2);
//         };
//     }
//   }, [showMap]);
//   useEffect(() => {
//     var map, direction_plugin;

  
//         map = new window.mappls.Map('map', {
//             center: [28.09, 78.3],
//             zoom: 5
//         });

//         map.addListener('load', function () {
//             /* Direction plugin initialization */
//             var direction_option = {
//                 map: map,
//                 divWidth: '350px',
//                 isDraggable: false,
//                 end: { label: 'India Gate', geoposition: "28.612964,77.229463" },
//                 Profile: [ 'trucking']
//             };

//             // Ensure mappls.direction function is available before calling it
//             if (typeof window.mappls.direction === 'function') {
//               window.mappls.direction(direction_option, function (data) {
//                     direction_plugin = data;
//                     console.log(direction_plugin);
//                 });
//             } else {
//                 console.error('MapPLS direction plugin is not available.');
//             }
//         });
//     }
// , []);

const [error, setError] = useState(null);

const modes = [ 'trucking'];
// useEffect(()=>{
  
//   if(formData.fromPincode && formData.toPincode){
//     calculateDistance();
//     console.log("from pincode",formData.fromPincode)
//   }
// },[formData.fromPincode, formData.toPincode])


  const calculateDistance = () => {
 

    const pincodeRegex = /^\d{6}$/;
    if (!pincodeRegex.test(formData.fromPincode) || !pincodeRegex.test(formData.fromPincode)) {
      setError("Please enter valid 6-digit pin codes.");
      return;
    }
    const apiKey = '6eab0fa444c0b3ea0ddce568eceef557'; // Replace with your actual MapPLS API key
console.log(formData.fromPincode)
    // Create an array to hold promises for fetching data for each mode
    const promises = modes.map(mode => {
      const apiUrl = `https://apis.mappls.com/advancedmaps/v1/${apiKey}/distance_matrix/${mode}/${formData.fromPincode}%3B${formData.fromPincode}?rtype=0&region=ind`;
      return fetch(apiUrl)
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then(data => {
          if (data.results && data.results.distances && data.results.durations) {
            const distance = data.results.distances[0][1] / 1000;
            const duration = data.results.durations[0][1] / 60;
            // setTotalKilometers(distance); // Store the distance in state
            console.log(distance);
            setError(null);
            return { mode, distance, duration };
          } else {
            throw new Error('Distance or duration not found in the response.');
          }
        })
        .catch(error => {
          console.error(`Error fetching data for ${mode}:`, error);
          return { mode, distance: null, duration: null, error: `Error fetching distance for ${mode}. ${error.message}` };
        });
    });

    // Execute all promises simultaneously using Promise.all
    Promise.all(promises)
      .then(results => {
        setResults(results);
        setError(null);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setError(`Error fetching distance. ${error.message}`);
      });
  };



    return (
        <div className='agent-book-now-bg'>
                       {showAnimation && <div className="overlay"><Postpaidbookinganimation /></div>}

        <AgentNavbar/>
        <div className="container "style={{minHeight:'100vh'}}>
            <div className="row shadow-sm">
                {/* Left Side (Form) */}
                <div className="col-lg-9  gridcolumn1">
                    <h2 style={{fontFamily:'Segoe UI',textShadow:'1px 2px 2px gray',textAlign:'center'}}>Book Now</h2>
                    <form >
                        {/* <div className='ms-3'>

                            <p>Registration Number: <b>{formatTruckNumber(truckNumber)}</b></p>
                            
                            <p>Wheels - Axles: <b> {truckWheels}</b></p>
                            <p>Max Weight:  <b>{truckMaxWeight} Tons</b></p>
                        </div> */}



                        <div className="grid-container-1 mt-4" >
                          
                          <div className='border-in-form'> <label> Registration Number:</label> <b>{formatTruckNumber(truckNumber)}</b></div>
                          <div  className='border-in-form' ><label >  Wheels - Axles: </label> <b> {truckWheels}</b></div>
                          <div  className='border-in-form'>   <label> Max Weight: </label>  <b>{truckMaxWeight} Tons</b></div>

                        <div  className='border-in-form'> <label htmlFor="text">Date :</label>
                            <b> {date}</b>
                               </div>
                            <div  className='border-in-form'> <label htmlFor="text">Time :</label>
                        <b> {time}</b>
                               </div>
                            <div  className='border-in-form'> <label htmlFor="text">From :</label>
                                <b> {from}</b>
                               </div>
                            <div  className='border-in-form'> <label htmlFor="text">To :</label>
                            <b> {to}</b>
                               </div>
                               <div></div>
                                    <div></div>
                            <div><label>1.From Sublocation:</label>
                            <select
          name="fromSublocation"
          value={selectedLoadingSublocation}
          onChange={(e) => setSelectedLoadingSublocation(e.target.value)}
          required
        >
          <option value="">Select From Sublocation</option>
          {loadingSublocations.map((sublocation, index) => (
            <option key={index} value={sublocation}>{sublocation}</option>
          ))}
        </select></div>
                            <div><label>2.To Sublocation:</label>
                            <select
          name="toSublocation"
          value={selectedUnloadingSublocation}
          onChange={(e) => setSelectedUnloadingSublocation(e.target.value)}
          required
        >
          <option value="">Select To Sublocation</option>
          {unloadingSublocations.map((sublocation, index) => (
            <option key={index} value={sublocation}>{sublocation}</option>
          ))}
        </select></div>
                                   
                            <div>
                              <label htmlFor="text">3.From Address:</label>
                                <input type="text" placeholder="Enter Address" className='border mt-0'  onChange={handleFormChange}  name="fromAddress"  required />
                                {/* {errors.formData.fromAddress && <div className="text-danger">{errors.formData.fromAddress}</div>} */}
                                </div>
                            <div> <label htmlFor="text">4.To Address:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                                <input type="text" placeholder="Enter Address" className='border'  onChange={handleFormChange} name="toAddress" required />
                                {/* {errors.toAddress && <div className="text-danger">{errors.toAddress}</div>} */}
                                </div>


                            <div> <label htmlFor="text" >5.From Pincode:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                                <input type="text" maxLength={6} minLength={6} placeholder="Enter Pincode"  value={formData.fromPincode}   className='border form-control 'style={{width:'fit-content'}} onChange={handleFormChange} name="fromPincode" required /></div>

                            <div> <label htmlFor="text">6.To Pincode:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                                <input type="text" maxLength={6} minLength={6} placeholder="Enter Pincode"   value={formData.toPincode}  className='border' onChange={handleFormChange} name="toPincode" required /></div>
                                <div>
                  <label htmlFor="text">  7.Type Of Material:</label>
                  <select
                    name="type"
                    className='mt-1'
                    onChange={handleFormChange} required
                  >
                    <option value="">Select Option </option>
                    <option value="iron">Iron</option>
                    <option value="cotton">Cotton</option>
                    <option value="woods">Woods</option>
                    <option value="others">Others</option>
                  </select>
                </div>
                <div>
          <label htmlFor="text">12.Load Weight:</label>
          <select
            name="loadWeight"
            className='mt-1'
            onChange={(e) => setSelectedLoadWeight(e.target.value)}
            value={selectedLoadWeight}
            required
          >
            <option value="">Select Load Weight</option>
            <option value="1-5T">1-5T</option>
            <option value="6-10T">6-10T</option>
            <option value="11-15T">11-15T</option>
            <option value="16-20T">16-20T</option>
            <option value="21-25T">21-25T</option>
            <option value="26-30T">26-30T</option>


            {/* Add more options for other weight ranges */}
          </select>
        </div>
</div>
                    </form>
</div>
                    {/* Right Side (Pricing) */}
                    <div className="col-lg-3   gridcolumn2">
                        <Typography variant='h4' className='fw-bold ms-3'>Pricing</Typography>
                        <table className="pricing-details table-hovered table-bordered mt-3 table-light">
                            <tr>
                              <td className='p-2'><strong>Price per Kilometer:</strong></td>
                              <td className='p-2' ><span className='fw-bold'>{pricePerTonKm}/-</span></td>
                            </tr>
                            <tr>
                              <td className='p-2'><strong>Total Kilometers:</strong></td>
                              <td className='p-2'><b>{totalkilometers} km</b></td>
                            </tr>
                            <tr>
                              <td className='p-2'><strong>Price:</strong></td>
                              <td className='p-2'><b>{finalPrice.toFixed(2)}/-</b></td>
                            </tr>
                          </table>

                        <br></br>
                        <div className='bookbtn'>
                        <Button type='submit' className='' variant='contained' color='warning'   onClick={openModal}>Book Now</Button>
                           </div>
                    </div>
                    
               
            </div>
        </div>
        <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Booking Details"
        className="modal-content" // Style this class
        overlayClassName="modal-overlay" // Style this class
      
      >
        {/* Display the filled details */}
        <div> 
          <Typography variant='h4' className='text-center'>Booking Details</Typography>
          {/* Display the details here */}
          <div class="container mt-3 " >
  <table class="table table-hover  table-primary">
    <tbody>
      <tr>
        <th scope="row">Registration Number</th>
        <td>{truckNumber}</td>
      </tr>
      <tr>
        <th scope="row">Wheels - Axles</th>
        <td>{truckWheels}</td>
      </tr>
      <tr>
        <th scope="row">Max Weight</th>
        <td>{truckMaxWeight} Tons</td>
      </tr>
      <tr>
        <th scope="row">Date</th>
        <td>{date}</td>
      </tr>
      <tr>
        <th scope="row">Time</th>
        <td>{time}</td>
      </tr>
      <tr>
        <th scope="row">From</th>
        <td>{from}</td>
      </tr>
      <tr>
        <th scope="row">To</th>
        <td>{to}</td>
      </tr>
      <tr>
        <th scope="row">From Sublocation</th>
        <td>{selectedLoadingSublocation}</td>
      </tr>
      <tr>
        <th scope="row">To Sublocation</th>
        <td>{selectedUnloadingSublocation}</td>
      </tr>
      <tr>
        <th scope="row">Type of Material</th>
        <td>{formData.type}</td>
      </tr>
      <tr>
        <th scope="row">From Address</th>
        <td>{formData.fromAddress}</td>
      </tr>
      <tr>
        <th scope="row">To Address</th>
        <td>{formData.toAddress}</td>
      </tr>
      <tr>
        <th scope="row">From Pincode</th>
        <td>{formData.fromPincode}</td>
      </tr>
      <tr>
        <th scope="row">To Pincode</th>
        <td>{formData.toPincode}</td>
      </tr>
      <tr>
        <th scope="row">Total Kilometers</th>
        <td>{totalkilometers} km</td>
      </tr>
      <tr>
        <th scope="row">Total Price</th>
        <td>{finalPrice.toFixed(2)}/-</td>
      </tr>
    </tbody>
  </table>
  <Typography className='op text-center' variant='h6'>*Total price includes taxes</Typography>
</div>


         

        {/* Buttons to confirm or cancel */}
        <div className='d-flex justify-content-center'>
          <Button variant='contained' color='success' onClick={handleBookNowClick}>Confirm</Button>
          <Button variant='contained' color='error' onClick={closeModal}>Cancel</Button>
        </div>
        </div>
      </Modal>
      {showMap && (
                <div>
                    <div id="map" style={{ width: '100%', height: '500px' }}></div>
                </div>
            )}
        </div>
        
    );
}
