import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea, CardActions, TableHead } from '@mui/material';
import './MyProfile.css';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, } from '@mui/material';

export default function MyProfile() {
  const name = sessionStorage.getItem('name');
  const phonenumber = sessionStorage.getItem('phoneNumber')
  const email = localStorage.getItem('onweremail')

  return (
    <div className='My-profile-bg' >
    <div className='card'  >
      <Card  id='card-bg' className='shadow' component={Paper}>
        <CardActionArea className='rounded-circle mt-3' id='card-image-area'>
          <img
            height='140'
            width='140px'
            src='https://th.bing.com/th/id/OIP.fio1nXsUCvmMVKmVqHO0cgHaHa?rs=1&pid=ImgDetMain'
            alt='profile'
            className='rounded-circle'
          />
        </CardActionArea>
        <Typography gutterBottom variant='h5' component='div' className='text-center mt-3'>
                      Personal Information
          </Typography>
        <CardActions>
          
          <TableContainer >
            <Table className='table-hover table-bordered'>
              <TableBody>
              
                <TableRow>
                  <TableCell size='small'>
                    <TableHead  className='fw-bold' color='text.secondary'>Full Name : </TableHead>
                  </TableCell>
                    <TableCell>
                      <TableBody className='p-1'><b>{name}</b></TableBody>
                    </TableCell>
                </TableRow>
                <TableRow>
                <TableCell size='small'>
                    <TableHead  className='p-1 fw-bold' color='text.secondary'>PH Number: </TableHead>
                  </TableCell>
                    <TableCell>
                      <TableBody className='p-1'><b>{phonenumber}</b></TableBody>
                    </TableCell>
                </TableRow>
                <TableRow>
                <TableCell size='small'>
                    <TableHead  className='p-1 fw-bold' color='text.secondary'>Email ID: </TableHead>
                  </TableCell>
                    <TableCell>
                      <TableBody className='p-1'><b>{email}</b></TableBody>
                    </TableCell>
                </TableRow>
                {/* <TableRow>
                <TableCell size='small'>
                    <TableHead  className='p-1 fw-bold' color='text.secondary'>Address: </TableHead>
                  </TableCell>
                    <TableCell>
                      <TableBody className='p-1'>flsjljlkfjslfjf</TableBody>
                    </TableCell>
                </TableRow> */}
              </TableBody>
            </Table>
          </TableContainer>
        </CardActions>
      </Card>
    </div>
  </div>
  );
}
