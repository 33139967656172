import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import React from "react";
import Dashboard from './Dashboard';
import OwnerInterface from "./OwnerInterface";
import AgentRegistration from "./AgentRegistration";
import AgentInfo from "./AgentInfo";
import NewTruck from "./NewTruck";
import TruckPosting from "./TruckPosting";
import OwnerBooking from "./OwnerBooking";
import AgentNavbar from "./AgentNavbar";
import AgentInterface from "./AgentInterface";
import AgentBooking from "./AgentBooking";
import AgentBooknow from "./AgentBooknow";
import AddSub from "./AddSub";
import AgentUpdate from "./AgentUpdate";
import { setToken } from "./tokenHelper";
import NewDriver from "./NewDriver";
import DriverInfo from "./DriverInfo";
import Footer from "./Footer";
import NewPost from "./NewPost";
import Calculator from "./Calculator";
import PaymentInterface from "./PaymentInterface";
import MyProfile from './MyProfile'
import FAQ from "./FAQ";
import MyForm from "./MyForm";
import ForgotPasswordOwner from "./ForgotPasswordOwner";
import ForgotPasswordAgent from "./ForgotPasswordAgent";
import Pincode from "./Pincode";
import DistanceCalculator from "./DistanceCalculator";
import Location from "./Location";
import AgentMyProfile from "./AgentMyProfile"
export default function App() {
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      // Set the token in the token helper
      setToken(token);
    }
  }, []);
  return (
    <div>
      

     
      <BrowserRouter >
        <Routes>
        <Route path='/' element={<Dashboard />}></Route>

          <Route path='Dashboard' element={<Dashboard />}></Route>

          <Route path='OwnerInterface' element={<OwnerInterface />}></Route>
          <Route path='AgentRegistration' element={<AgentRegistration  />}></Route>
          <Route path='DistanceCalculator' element={<DistanceCalculator  />}></Route>
          <Route path='Location' element={<Location  />}></Route>

          <Route path='AgentInfo' element={<AgentInfo  />}></Route>
          <Route path='AgentInfo' element={<AgentInfo  />}></Route>
          <Route path='NewDriver' element={<NewDriver  />}></Route>
          <Route path='Pincode' element={<Pincode  />}></Route>
          <Route path='AgentMyProfile' element={<AgentMyProfile  />}></Route>

          <Route path='NewTruck' element={<NewTruck  />}></Route>
          <Route path='DriverInfo' element={<DriverInfo  />}></Route>

          <Route path='TruckPosting' element={<TruckPosting  />}></Route>
          <Route path='OwnerBooking' element={<OwnerBooking />}></Route>
          <Route path='AgentNavbar' element={<AgentNavbar />}></Route>
          <Route path='AgentUpdate' element={<AgentUpdate />}></Route>

          <Route path='AgentInterface' element={<AgentInterface />}></Route>
          <Route path='AgentBooking' element={<AgentBooking />}></Route>

          <Route path='AgentBooknow' element={<AgentBooknow />}></Route>
          <Route path='AddSub' element={<AddSub />}></Route>
          <Route path='MyForm' element={<MyForm />}></Route>

          <Route path='newpost' element={<NewPost />}></Route>
          <Route path='cal' element={<Calculator />}></Route>
          <Route path='ForgotPasswordOwner' element={<ForgotPasswordOwner />}></Route>
          <Route path='ForgotPasswordAgent' element={<ForgotPasswordAgent />}></Route>

          <Route path='PaymentInterface' element={<PaymentInterface />}></Route>
        
          
          <Route path='myprofile' element={<MyProfile />}></Route>

          <Route path='faq' element={<FAQ />}></Route>


        </Routes>
      </BrowserRouter>
      <Footer/>
      
     

    </div>
  )
}
