import React, { useState } from 'react';

const DistanceCalculator = () => {
  const [fromPincode, setFromPincode] = useState('');
  const [toPincode, setToPincode] = useState('');
  const [result, setResult] = useState({ distance: null, duration: null, error: null });

  const calculateDistance = () => {
    // Replace 'YOUR_API_KEY' with your actual MapPLS API key
    const apiKey = '6eab0fa444c0b3ea0ddce568eceef557';
    const apiUrl = `https://apis.mappls.com/advancedmaps/v1/${apiKey}/distance_matrix/driving/${fromPincode}%3B${toPincode}?rtype=0&region=ind`;

    fetch(apiUrl)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        console.log('API Response:', data);

        // Check if the distances and durations arrays exist and have values
        if (data.results && data.results.distances && data.results.distances.length > 0 && data.results.durations && data.results.durations.length > 0) {
          // Extract distance and duration information from the API response
          const distanceInKm = data.results.distances[0][1] / 1000;
          const durationInSeconds = data.results.durations[0][1];

          // Convert duration to minutes
          const durationInMinutes = durationInSeconds / 60;

          // Set the result in state
          setResult({ distance: distanceInKm.toFixed(2), duration: durationInMinutes.toFixed(2), error: null });
        } else {
          // Set an error message if distances or durations are not present
          setResult({ distance: null, duration: null, error: 'Error fetching distance. Distances or durations not found in the response.' });
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);

        // Set the error in state
        setResult({ distance: null, duration: null, error: `Error fetching distance. ${error.message}` });
      });
  };

  return (
    <div className="container mt-5">
    <h2 className="mb-4">Pincode-to-Pincode Distance Calculator</h2>
    <div className="mb-3">
      <label htmlFor="fromPincode" className="form-label">From Pincode:</label>
      <input type="text" style={{maxWidth:'20%'}} className="form-control" id="fromPincode" value={fromPincode} onChange={(e) => setFromPincode(e.target.value)} />
    </div>
    <div className="mb-3">
      <label htmlFor="toPincode" className="form-label">To Pincode:</label>
      <input type="text"  style={{maxWidth:'20%'}} className="form-control" id="toPincode" value={toPincode} onChange={(e) => setToPincode(e.target.value)} />
    </div>
    <button type="button" className="btn btn-primary" onClick={calculateDistance}>Calculate Distance</button>
    <div className="mt-3">
      {result.error && <div className="alert alert-danger mt-3" role="alert">{result.error}</div>}
      {result.distance !== null && (
        <div className="table-responsive mt-3">
          <table className="table table-bordered table-striped">
            <thead>
              <tr>
                <th colSpan="2">Search Details</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>From Pincode:</td>
                <td>{fromPincode}</td>
              </tr>
              <tr>
                <td>To Pincode:</td>
                <td>{toPincode}</td>
              </tr>
              <tr>
              <td colSpan="3" style={{fontSize:'17px'}}><b>Distance and Duration</b></td>
              </tr>
              <tr>
                <td>Distance:</td>
                <td ><span className="badge bg-success" style={{fontSize:'17px'}}>{result.distance} kilometers</span></td>
              </tr>
              <tr>
                <td>Duration:</td>
                <td><span className="badge bg-info"style={{fontSize:'17px'}}>{result.duration} minutes</span></td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  </div>
  );
};

export default DistanceCalculator;
