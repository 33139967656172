import React, { useState } from 'react';
import './FP.css';
import emailjs from '@emailjs/browser';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
const Forgetpassword = () => {
  const [owneremail, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [rotp, rsetOtp] = useState('');
  const [isEmailInputDisabled, setIsEmailInputDisabled] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [updatePassword, setUpdatePassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [additional, setAdditional] = useState(false);
  const [error, setError] = useState('');
const navigate = useNavigate('')
  const handleRequestOTP = (e) => {
    e.preventDefault();
    if (owneremail === '') {
      window.alert('PLEASE ENTER VALID EMAIL!');
      window.location.reload();
    } else {
      fetch('http://localhost:9001/verifyemail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            owneremail,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            setIsEmailInputDisabled(true);
            rsetOtp(data.otp);
            console.log({ owneremail, cnotp: data.otp ,name:data.ownername});
            emailjs
        .send(
        'service_qucoi14',
        'template_vcrqn4p',
           { otp: data.otp ,email: owneremail,name:data.ownername},
           'zWYeclc0nhy9Cdlkj'
         )
         .then(
           (result) => {
            console.log(result.text);
             window.alert('OTP sent successfully.');
           },
         (error) => {
             console.log(error.text);
             window.alert('Error sending OTP.');
        }
);
          } else {
            window.alert('Email is not registered with us. Please try again.');
          }
        })
        .catch((error) => {
          console.error('Error:', error);
          window.alert('An error occurred. Please try again later.');
        });
    }
  };

  const handleVerifyOTP = () => {
    setIsVerified(true);
    if (rotp === parseInt(otp)) {
      console.log('Success');
      setAdditional(true);
    } else {
      window.alert('YOU ENTERED WRONG OTP. TRY AGAIN!');
      window.location.reload();
    }
  };

  const handleUpdatePassword = () => {
    if (updatePassword === '') {
      setError('Please enter the password');
      return;
    }

    if (confirmPassword === '') {
      window.alert('Please confirm the password.');
      return;
    }

    if (updatePassword !== confirmPassword) {
      setError('Password do not match, please try again');
      return;
    }

    axios.put('http://localhost:9001/ownerpass', {
      owneremail,
      newPassword: confirmPassword, // Assuming your backend expects 'newPassword' instead of 'password'
    })
    .then((response) => {
        const data = response.data;
        if (data.success) {
          setEmail('');
          setOtp('');
          rsetOtp('');
          setIsEmailInputDisabled(false);
          setIsVerified(false);
          setUpdatePassword('');
          setConfirmPassword('');
          setAdditional(false);
          window.alert('Password updated successfully!');
          navigate('/Dashboard')
        } else {
          setError('Failed to update password. Please try again.');
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        setError('An error occurred. Please try again later.');
      });
  };

  return (
    <div className="forget-bg">
      <div className="form-container">
        <div>
          <form onSubmit={handleRequestOTP}>
            <label htmlFor="email">Enter Email:</label>
            <input
              type="email"
              id="email"
              className="forget-input"
              placeholder="example@example.com"
              value={owneremail}
              onChange={(e) => setEmail(e.target.value)}
              disabled={isEmailInputDisabled}
            />
            <button
              id="otp-request-btn"
              type="submit"
              disabled={isEmailInputDisabled}
              className="mt-1 forget-btn"
            >
              {isEmailInputDisabled ? 'Resend OTP' : 'Get OTP'}
            </button>
          </form>
        </div>
        <div id="otp-container" style={{ display: isEmailInputDisabled ? 'block' : 'none' }}>
          <label htmlFor="otp">Enter OTP:</label>
          <input
            type="text"
            id="otp"
            className="forget-input"
            placeholder="Enter OTP"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            disabled={isVerified}
          />
          <button
            id="verify-otp-btn"
            onClick={handleVerifyOTP}
            disabled={isVerified}
            className="mt-1 forget-btn"
          >
            {isVerified ? 'Verified' : 'Verify OTP'}
          </button>
  
          {additional && (
            <div>
              <label htmlFor="updatePassword">Update Password:</label>
              <input
                type="password"
                id="updatePassword"
                placeholder="Enter New Password"
                value={updatePassword}
                className="form-control forget-input"
                onChange={(e) => setUpdatePassword(e.target.value)}
              />
  
              <label htmlFor="confirmPassword">Confirm Password:</label>
              <input
                type="password"
                id="confirmPassword"
                className="form-control forget-input"
                placeholder="Confirm New Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <p style={{ color: 'red', textAlign: 'center' }}>{error}</p>
              <button onClick={handleUpdatePassword} className="btn btn-primary forget-btn">
                Update Password
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
  
};

export default Forgetpassword;
