import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import './NewTruck.css'
import './NewTruck.css';
import OwnerNavbar from './OwnerNavbar';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import Footer from "./Footer";

export default function NewTruck() {
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const location = useLocation();
  const feildcrn = localStorage.getItem('feildCRN')
  console.log('feild',feildcrn)
  const [openBackdrop, setOpenBackdrop] = React.useState(false);

    const token = localStorage.getItem('userToken')
  
    const headers = {
        Authorization: `Bearer ${token}`,
      };
  const [truckNumber, setTruckNumber] = useState('');
  const [truckMaxWeight, setTruckMaxWeight] = useState('');
  const [truckWheels, setTruckWheels] = useState('');
  const [truckImages, setTruckImages] = useState({
    uploadRegistration:null,
    truckFrontSideWithNumberPlate: null,
    truckBackSideWithNumberPlate: null,
    truckCabin: null,
    rightside:null,
    leftside:null,
    truckOdometer: null,
    truckVideo: null,
    truckPermit: null,
    truckFit: null,
    truckPollutionCertificate: null,
    truckInsuranceCertificate: null,
    truckOwnerPassportSizePhoto: null,
  });
  const [truckPermitValidity, setTruckPermitValidity] = useState('');
  const [truckFitValidity, setTruckFitValidity] = useState('');
  const [truckPollutionCertificateValidity, setTruckPollutionCertificateValidity] = useState('');
  const [truckInsuranceCertificateValidity, setTruckInsuranceCertificateValidity] = useState('');
const crn = localStorage.getItem('userCRN')
console.log(crn)
 const name = localStorage.getItem('name')
 useEffect(() => {
  // Check truck status when component mounts
  checkTruckStatus();
}, [truckNumber]);

const checkTruckStatus = async () => {
  try {
    console.log('truck',truckNumber)
    if(truckNumber.length > 9){
    setOpenBackdrop(true);
    const response = await axios.get(`https://mynode1.trucksbooking.in/checkTruckStatus/${truckNumber}`, { headers });
    console.log(response.data); 
    const truckStatus = response.data[0].status;
console.log('ser',truckStatus)
    if (truckStatus === 'pending') {  
      // Truck is still under verification
      alert('Truck is still under verification. Please wait for approval.');
      setOpenBackdrop(false);
      setTruckNumber('')
    } else if (truckStatus === 'rejected') {
      // Truck is rejected, allow user to proceed
      alert('Truck is rejected. You can proceed with registration.');
      setOpenBackdrop(false);
    } else if (truckStatus === 'Completed') {
      // Truck is already registered
      alert('Truck is already registered.');
      setOpenBackdrop(false);
      // You may redirect or handle this case as needed
      setTruckNumber('')

    }}
  } catch (error) {
    setOpenBackdrop(false);
    console.error('Error checking truck status:', error);
  }
};

  const getDateAfterOneWeek = () => {
    const today = new Date();
    const oneWeekAfter = new Date();
    oneWeekAfter.setDate(today.getDate() + 7);
    return oneWeekAfter;
  };
  const [errors, setErrors] = useState({
    truckNumber: '',
  });

  const validateFileSize = (file, maxSizeInBytes) => {
    const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg','video/mp4'];
    if (!allowedTypes.includes(file.type)) {
      return 'File type should be JPG, JPEG, or PNG.';
    }
    if (file.size > maxSizeInBytes) {
      return `File size should not exceed ${maxSizeInBytes / (1024 * 1024)} MB.`;
    }
    return '';
  };
  const validateForm = () => {
    const newErrors = {};

    if (truckNumber.length !== 10) {
      newErrors.truckNumber = 'Truck number must be exactly 10 characters long.';
      console.log('eoo')
    }

    // Validate file sizes
    const maxSize = 5 * 1024 * 1024; // 15MB

    for (const key in truckImages) {
      if (truckImages[key]) {
        const errorMessage = validateFileSize(truckImages[key], maxSize);
        if (errorMessage) {
          newErrors[key] = errorMessage;
        }
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  
  const handleSubmit = (e) => {

    e.preventDefault();
   
    if (!validateForm()) {
      return;
      
    }
    setOpenBackdrop(true);
    const formData = new FormData();
    const currentDate = new Date().toLocaleDateString();
    formData.append('truckNumber', truckNumber);
    formData.append('truckMaxWeight', truckMaxWeight);
    formData.append('truckWheels', truckWheels);
    for (const key in truckImages) {
      if (truckImages[key]) {
        formData.append(key, truckImages[key]);
      }
    }
    formData.append('truckPermitValidity', truckPermitValidity);
    formData.append('truckFitValidity', truckFitValidity);
    formData.append('truckPollutionCertificateValidity', truckPollutionCertificateValidity);
    formData.append('truckInsuranceCertificateValidity', truckInsuranceCertificateValidity);
    formData.append('crn', crn);
    formData.append('reqdate', currentDate); 
    formData.append('name', name);
    formData.append('feildcrn', feildcrn);


    axios.post('https://mynode1.trucksbooking.in/truck', formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Important for sending files
        },headers:headers
      })
      .then((response) => {
        console.log(response.data);
        alert('Your request is in process. Kindly check in agent info!');
        setOpenBackdrop(false);
        navigate('/OwnerInterface', { state: { crn } });
        // Handle success, e.g., display a success message
      })
      .catch((error) => {
        console.error(error);
        setOpenBackdrop(false);
        // Handle error, e.g., display an error message
      });

    // Reset form fields
   
    // ... Clear other fields
  };

  useEffect(()=>{
    if(!token){
  navigate('/Login')
    }
  })
 
  const formatTruckNumber = (value) => {
    // Format truck number as AA11AA1111
    const formattedValue = value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();
    const regex = /^[A-Z]{0,2}[0-9]{0,2}[A-Z]{0,2}[0-9]{0,4}$/;
    if (regex.test(formattedValue)) {
      setTruckNumber(formattedValue);
    }
  };
  const handleChange = (e) => {
    const selectedFile = e.target.files[0];
    const maxSize = 5 * 1024 * 1024; // 5MB
    const fieldName = e.target.name;
  
    if (selectedFile && selectedFile.size > maxSize) {
      setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: 'File size exceeds the maximum limit of 5MB.' }));
      console.log('error');
      e.target.value = ''; // Clear the file input field
    } else {
      
      setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: '' })); // Clear any previous errors
    }
  };
  const handleChange1 = (e) => {
    const selectedFile = e.target.files[0];
    const maxSize = 20 * 1024 * 1024; // 5MB
    const fieldName = e.target.name;
  
    if (selectedFile && selectedFile.size > maxSize) {
      setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: 'File size exceeds the maximum limit of 20MB.' }));
      console.log('error');
      e.target.value = ''; // Clear the file input field
    } else {
     
      setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: '' })); // Clear any previous errors
    }
  };
  const handleChangetruck = (e) => {
    const input = e.target.value;
                  const formattedInput = input.substring(0,2).toUpperCase().replace(/[^A-Z]/g, '')+
                  input.substring(2,4).replace(/[^0-9]/g, '').substring(0, 2)+
                  input.substring(4,6).toUpperCase().replace(/[^A-Z]/g, '')+  
                  input.substring(6).replace(/[^0-9]/g, '');
              
    if (input.length < 10) {
      setError('Truck number must be 10 characters long.');
    } else if (!/^[A-Z]{2}\d{2}[A-Z]{2}\d{4}$/.test(input)) {
      setError('Invalid format for truck number.');
    } else {
      setError('');
    }
    setTruckNumber(formattedInput);
  };
  return (
    <div>
      <OwnerNavbar />
      <div>
      <div className="hello-2">
        <div className="hii-2 shadow">
            <h1 className='txt'>Truck Registration</h1>
          <form onSubmit={handleSubmit} encType="multipart/form-data">
            <div className="grid-container-2">
              <div>
                <label htmlFor="truckNumber">1. Truck Number<sup style={{color:'red',fontSize:'15px',marginBottom:'0'}}>*</sup></label>
                <input
        type="text"
        id="truckNumber"className="form-control"
        name="truckNumber"
        value={truckNumber}
        onChange={handleChangetruck}
        placeholder="Enter Truck Number"
        maxLength={10}
        minLength={10}
        required
      /> 
      {error && <div style={{ color: 'red' }}>{error}</div>} <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openBackdrop}
            onClick={null} // Set onClick to null to disable closing on click
          >
            <div>
            <CircularProgress color="inherit" />
            <br/>
            <Typography variant="h6" color="inherit" component="div" style={{marginTop: '10px'}}>
              Loading...
            </Typography>
            </div>
    
          </Backdrop>   
        
              </div>
              <div>
                <label htmlFor="maxWeight">2. Truck Max Weight(Tons)<sup style={{color:'red',fontSize:'15px'}}>*</sup></label>
                <input
                  type="text"
                  id="maxWeight"className="form-control"
                  name="maxWeight"
                  value={truckMaxWeight}
                  onInput={(e) => {
                    let inputValue = e.target.value;
                    
                    // Remove non-digit characters
                    inputValue = inputValue.replace(/\D/g, '');
                    
                    // Prevent entering '0' at the beginning
                    if (inputValue.startsWith('0')) {
                      inputValue = inputValue.substring(1); // Remove the leading '0'
                    }
                    
                    setTruckMaxWeight(inputValue);
                  }} maxLength={3}
                  placeholder="Enter Truck Max Weight"
                  required
                />
              </div>
              <div>
                <label htmlFor="wheelsAxles">3. Truck Wheels - Axles<sup style={{color:'red',fontSize:'15px'}}>*</sup></label>
                <select
                  id="wheelsAxles"
                  name="wheelsAxles"className="form-control"
                  value={truckWheels}
                  style={{width:'90%',borderRadius:'7px'}}
                  onChange={(e) => setTruckWheels(e.target.value)}
                  required
                >
                  <option value="">- Choose Wheels -</option>
                  <option value="4 Wheels - 2 Axles">4 Wheels - 2 Axles</option>
                  <option value="6 Wheels - 2 Axles">6 Wheels - 2 Axles</option>
                  <option value="10 Wheels - 3 Axles">10 Wheels - 3 Axles</option>
                  <option value="12 Wheels - 4 Axles">12 Wheels - 4 Axles</option>
                  <option value="14 Wheels - 5 Axles">14 Wheels - 5 Axles</option>
                  <option value="16 Wheels - 5 Axles">16 Wheels - 5 Axles</option>
                </select>
              </div>
              <div>
  <label htmlFor="registrationCertificate">
    4. Upload Registration Certificate<sup style={{color:'red',fontSize:'15px'}}>*</sup>
  </label>
  <input
    type="file"
    id="registrationCertificate"
    name="registrationCertificate" className={`form-control ${errors['truckVideo'] ? 'error-input' : ''}`}
    accept=".jpg, .jpeg, .png"
    onChange={(e) => {handleChange(e);setTruckImages({ ...truckImages, uploadRegistration: e.target.files[0] })}}
    required/>

  {errors['registrationCertificate'] && (
    <span className="error">{errors['registrationCertificate']}</span>
    
  )}
<span style={{ opacity: 0.5 }}>JPG,JPEG,PNG,pdf(Max size: 5MB)</span></div>
<br/>
<br/>
<div></div>
<div></div>
 <div style={{display:'block'}}>
  <h3 className='txt' style={{textAlign:'start'}}>Truck Photos</h3>
  
  </div>

  <div></div>
 
 

  <div>
  <label htmlFor="frontSideWithNumberPlate">5. Truck Front Side With Number Plate<sup style={{ color: 'red', fontSize: '15px', verticalAlign: 'middle', lineHeight: '0' }}>*</sup>
  </label>
  <input
    type="file"
    id="frontSideWithNumberPlate"
    name="truckFrontSideWithNumberPlate" className={`form-control ${errors['truckFrontSideWithNumberPlate'] ? 'error-input' : ''}`}
    accept=".jpg, .jpeg, .png"
    onChange={(e) => {handleChange(e);setTruckImages({ ...truckImages, truckFrontSideWithNumberPlate: e.target.files[0] })}}
    required/>
  {errors['truckFrontSideWithNumberPlate'] && (
    <span className="error">{errors['truckFrontSideWithNumberPlate']}</span>
  )}<span style={{ opacity: 0.5 }}>JPG,JPEG,PNG,pdf(Max size: 5MB)</span>
</div>

<div>
  <label htmlFor="frontSideWithNumberPlate">6. Truck Right Side<sup style={{color:'red',fontSize:'15px'}}>*</sup></label>
  <input
    type="file"
    id="rightside"
    name="rightside" className={`form-control ${errors['rightside'] ? 'error-input' : ''}`}
    accept=".jpg, .jpeg, .png"
    onChange={(e) => {handleChange(e);setTruckImages({ ...truckImages, rightside: e.target.files[0] })}}
    required
  />
  {errors['rightside'] && (
    <span className="error">{errors['rightside']}</span>
  )}<span style={{ opacity: 0.5 }}>JPG,JPEG,PNG,pdf(Max size: 5MB)</span>
</div>

{/* Add more file input fields for other images here */}
<div>
  <label htmlFor="backSideWithNumberPlate">7. Truck Back Side With Number Plate<sup style={{color:'red',fontSize:'15px'}}>*</sup></label>
  <input
    type="file"
    id="backSideWithNumberPlate" className={`form-control ${errors['truckBackSideWithNumberPlate'] ? 'error-input' : ''}`}
    name="truckBackSideWithNumberPlate"
    accept=".jpg, .jpeg, .png"
    onChange={(e) => {handleChange(e);setTruckImages({ ...truckImages, truckBackSideWithNumberPlate: e.target.files[0] })}}
    required
  />
  {errors['truckBackSideWithNumberPlate'] && (
    <span className="error">{errors['truckBackSideWithNumberPlate']}</span>
  )}<span style={{ opacity: 0.5 }}>JPG,JPEG,PNG,pdf(Max size: 5MB)</span>
</div>
<div>
  <label htmlFor="backSideWithNumberPlate">8. Truck Left Side<sup style={{color:'red',fontSize:'15px'}}>*</sup></label>
  <input
    type="file"
    id="leftside" className={`form-control ${errors['leftside'] ? 'error-input' : ''}`}
    name="leftside"
    accept=".jpg, .jpeg, .png"
    onChange={(e) => {handleChange(e);setTruckImages({ ...truckImages, leftside: e.target.files[0] })}}
    required
  />
  {errors['leftside'] && (
    <span className="error">{errors['leftside']}</span>
  )}<span style={{ opacity: 0.5 }}>JPG,JPEG,PNG,pdf(Max size: 5MB)</span>
</div>

<div>
  <label htmlFor="cabin">9. Truck Cabin<sup style={{color:'red',fontSize:'15px'}}>*</sup></label>
  <input
    type="file"
    id="cabin" className={`form-control ${errors['truckCabin'] ? 'error-input' : ''}`}
    name="truckCabin"
    accept=".jpg, .jpeg, .png"
    onChange={(e) => {handleChange(e);setTruckImages({ ...truckImages, truckCabin: e.target.files[0] })}}
    required
  />
  {errors['truckCabin'] && (
        <span className="error">{errors['truckCabin']}</span>
      )}<span style={{ opacity: 0.5 }}>JPG,JPEG,PNG,pdf(Max size: 5MB)</span>
</div>
<div>
  <label htmlFor="odometer">10. Truck Odometer<sup style={{color:'red',fontSize:'15px'}}>*</sup></label>
  <input
    type="file"
    id="odometer" className={`form-control ${errors['truckOdometer'] ? 'error-input' : ''}`}
    name="truckOdometer"
    accept=".jpg, .jpeg, .png"
  onChange={(e) => {handleChange(e);setTruckImages({ ...truckImages, truckOdometer: e.target.files[0] })}}
    required
  />
  {errors['truckOdometer'] && <span className="error">{errors['truckOdometer']}</span>}<span style={{ opacity: 0.5 }}>JPG,JPEG,PNG,pdf(Max size: 5MB)</span>
</div>
<div>
  <label htmlFor="video">11. Truck Video<sup style={{color:'red',fontSize:'15px'}}>*</sup></label>
  <input
    type="file"
    id="video"
    name="truckVideo"
    className={`form-control ${errors['truckVideo'] ? 'error-input' : ''}`}
    accept=".mp4, .avi, .mkv"
    onChange={(e) => { handleChange1(e); setTruckImages({ ...truckImages, truckVideo: e.target.files[0] })}}
    required
  />
  {errors['truckVideo'] && <span className="error" style={{ color: 'red' }}>{errors['truckVideo']}</span>}<span style={{ opacity: 0.5 }}>mp4 (Max size: 20MB)</span>
</div>
<div>
  <label htmlFor="permitCertificate">12. Truck Permit Certificate<sup style={{color:'red',fontSize:'15px'}}>*</sup></label>
  <input
    type="file"
    id="permitCertificate" className={`form-control ${errors['truckPermit'] ? 'error-input' : ''}`}
    name="truckPermit"
    accept=" .jpg, .jpeg, .png"
    onChange={(e) => { handleChange(e); setTruckImages({ ...truckImages, truckPermit: e.target.files[0] })}}
    required
  />
  {errors['truckPermit'] && <span className="error">{errors['truckPermit']}</span>}<span style={{ opacity: 0.5 }}>JPG,JPEG,PNG,pdf(Max size: 5MB)</span>
</div>
<div>
  <label htmlFor="permitCertificateValidity">13. Truck Permit Certificate Validity<sup style={{color:'red',fontSize:'15px'}}>*</sup></label>
  <input
        type="date"
        id="permitCertificateValidity"
        className="form-control"
        name="permitCertificateValidity"
        style={{ textTransform: 'uppercase' }}
        value={truckPermitValidity}
        onChange={(e) => setTruckPermitValidity(e.target.value)}
        min={getDateAfterOneWeek().toISOString().split('T')[0]} // Disable previous dates
        required
      />
</div>
<div>
  <label htmlFor="fitnessCertificate">14. Truck Fitness Certificate<sup style={{color:'red',fontSize:'15px'}}>*</sup></label>
  <input
    type="file"
    id="fitnessCertificate"
    name="truckFit" className={`form-control ${errors['truckFit'] ? 'error-input' : ''}`}
    accept=" .jpg, .jpeg, .png"
    onChange={(e) => { handleChange(e); setTruckImages({ ...truckImages, truckFit: e.target.files[0] })}}
    required
  />
  {errors['truckFit'] && <span className="error">{errors['truckFit']}</span>}<span style={{ opacity: 0.5 }}>JPG,JPEG,PNG,pdf(Max size: 5MB)</span>
</div>
<div>
  <label htmlFor="fitnessCertificateValidity">15. Truck Fitness Certificate Validity<sup style={{color:'red',fontSize:'15px'}}>*</sup></label>
  <input
    type="date"
    id="fitnessCertificateValidity"
    name="fitnessCertificateValidity" className={`form-control ${errors['truckVideo'] ? 'error-input' : ''}`}
    style={{ textTransform: 'uppercase' }}
    value={truckFitValidity}
    onChange={(e) => setTruckFitValidity(e.target.value)}
    min={getDateAfterOneWeek().toISOString().split('T')[0]} 
    required
  />
</div>
<div>
  <label htmlFor="pollutionCertificate">16. Truck Pollution Certificate<sup style={{color:'red',fontSize:'15px'}}>*</sup></label>
  <input
    type="file"
    id="pollutionCertificate" className={`form-control ${errors['truckPollutionCertificate'] ? 'error-input' : ''}`}
    name="truckPollutionCertificate"
    accept=" .jpg, .jpeg, .png"
    onChange={(e) => { handleChange(e); setTruckImages({ ...truckImages, truckPollutionCertificate: e.target.files[0] })}}
    required
  />
  {errors['truckPollutionCertificate'] && <span className="error">{errors['truckPollutionCertificate']}</span>}<span style={{ opacity: 0.5 }}>JPG,JPEG,PNG,pdf(Max size: 5MB)</span>
</div>
<div>
  <label htmlFor="pollutionCertificateValidity">17. Truck Pollution Certificate Validity<sup style={{color:'red',fontSize:'15px'}}>*</sup></label>
  <input
    type="date"
    id="pollutionCertificateValidity" className={`form-control ${errors['truckVideo'] ? 'error-input' : ''}`}
    name="pollutionCertificateValidity"
    style={{ textTransform: 'uppercase' }}
    value={truckPollutionCertificateValidity}
    onChange={(e) => setTruckPollutionCertificateValidity(e.target.value)}
    min={getDateAfterOneWeek().toISOString().split('T')[0]} 
    required
  />
</div>
<div>
  <label htmlFor="insuranceCertificate">18. Truck Insurance Certificate<sup style={{color:'red',fontSize:'15px'}}>*</sup></label>
  <input
    type="file"
    id="insuranceCertificate"
    name="truckInsuranceCertificate" className={`form-control ${errors['truckInsuranceCertificate'] ? 'error-input' : ''}`}
    accept=" .jpg, .jpeg, .png"
    onChange={(e) => { handleChange(e); setTruckImages({ ...truckImages, truckInsuranceCertificate: e.target.files[0] })}}
    required
  />
  {errors['truckInsuranceCertificate'] && <span className="error">{errors['truckInsuranceCertificate']}</span>}<span style={{ opacity: 0.5 }}>JPG,JPEG,PNG,pdf(Max size: 5MB)</span>
</div>
<div>
  <label htmlFor="insuranceCertificateValidity">19. Truck Insurance Certificate Validity<sup style={{color:'red',fontSize:'15px'}}>*</sup></label>
  <input
    type="date"
    id="insuranceCertificateValidity"
    name="insuranceCertificateValidity" className={`form-control ${errors['truckVideo'] ? 'error-input' : ''}`}
    style={{ textTransform: 'uppercase' }}
    value={truckInsuranceCertificateValidity}
    onChange={(e) => 
    
      setTruckInsuranceCertificateValidity(e.target.value)}
    min={getDateAfterOneWeek().toISOString().split('T')[0]} 
    required
  />
</div>
<div>
  <label htmlFor="ownerPhoto">20. Truck Owner Passport Size Photo<sup style={{color:'red',fontSize:'15px'}}>*</sup></label>
  <input
    type="file"
    id="ownerPhoto"
    name="ownerPhoto"
    accept=".jpg, .jpeg, .png" className={`form-control ${errors['truckOwnerPassportSizePhoto'] ? 'error-input' : ''}`}
    onChange={(e) => {
      handleChange(e); 
      setTruckImages({ ...truckImages, truckOwnerPassportSizePhoto: e.target.files[0] })}}
    required/>
  {errors['truckOwnerPassportSizePhoto'] && (
    <span className="error">{errors['truckOwnerPassportSizePhoto']}</span>
  )}<span style={{ opacity: 0.5 }}>JPG,JPEG,PNG,pdf(Max size: 5MB)</span>
</div>
</div>


    <div className="flex1">
              <button type="submit" className='btn btn-primary p-1 mb-3'>Submit</button>
              <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
        onClick={null} // Set onClick to null to disable closing on click
      >
        <div>
        <CircularProgress color="inherit" />
        <br/>
        <Typography variant="h6" color="inherit" component="div" style={{marginTop: '10px'}}>
          Loading...
        </Typography>
        </div>

      </Backdrop>   
            </div>
          </form>
        </div>
      </div>
      </div>

    </div>
  );
}
